<template>
  <div class="select-renderer" v-if="this.params.node.group === false">
    <v-select
      v-model="selectedOption"
      :options="options"
      :clearable="false"
      :no-async="true"
      appendToBody
      v-on:input="onSelectChange"
    ></v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  data() {
    return {
      selectedOption: null,
      options: []
    }
  },
  mounted() {
    this.options = this.params.options
    this.selectedOption = this.options.find(
      (o) => o.value === this.params.value
    )
  },
  components: {
    vSelect
  },
  methods: {
    onSelectChange(selectedValue) {
      this.params.node.setDataValue(
        this.params.colDef.field,
        selectedValue.value
      )
    }
  }
}
</script>
